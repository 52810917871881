.resizable {
    background: none;
    cursor: pointer;
    transform-origin: center;
    z-index: 1; 
    position: absolute !important;
    text-align: left;
    font-size: 13px;
    color: #fff;
    vertical-align: middle;
    /* transform: rotate(90deg);  */
  }

  .resizable:hover {
    opacity: 0.9;
  }
  .resizable .resizers{
    width: 100%;
    height: 100%;
    border: 1px dashed #2ba8e0;
    box-sizing: border-box;
    z-index: 100;
    background: #2ba8e0;
    opacity: 0.8;
    vertical-align: middle;
    padding: 10px;
  }

  .resizable .resizers .resizer{
    width: 1px;
    height: 40px;
     /* border-radius: 50%; */
    background: #2ba8e0; 
    /*box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);*/
    border: 2px solid #fff;
    position: absolute;
  }

  .resizable .resizers .resizer.top {
    left: 45%;
    top: -8px;
    cursor: n-resize; /*resizer cursor*/
  }

  .resizable .resizers .resizer.left {
    left: -3px;
    top: 0px;
    cursor: w-resize; /*resizer cursor*/
  }


  .resizable .resizers .resizer.right {
    right: -3px;
    top: 0px;
    cursor: e-resize; /*resizer cursor*/
  }



  .resizable .resizers .resizer.bottom {
    left: 45%;
    bottom: -8px;
    cursor: s-resize; /*resizer cursor*/
  }

  .resizable .resizers .resizer.top-left {
    left: -8px;
    top: -8px;
    cursor: nw-resize; /*resizer cursor*/
  }
  .resizable .resizers .resizer.top-right {
    right: -8px;
    top: -8px;
    cursor: ne-resize;
  }
  .resizable .resizers .resizer.bottom-left {
    left: -8px;
    bottom: -8px;
    cursor: sw-resize;
  }
  .resizable .resizers .resizer.bottom-right {
    right: -8px;
    bottom: -8px;
    cursor: se-resize;
  }